<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="3">
          <CInput  v-model="adjustment_id" placeholder="Adjustment ID"/>
        </CCol>
        <CCol lg="3">
          <CInput  v-model="booking_no" placeholder="Booking No."/>
        </CCol>
         <CCol lg="3">
          <CInput  v-model="plate_no" placeholder="Plate No."/>
        </CCol>
       <CCol lg="3">
          <CInput  v-model="destination" placeholder="Zone"/>
        </CCol>
       
        <CCol lg="3">
        <v-select 
										label="name" 
										:options="personnelList.data"
										:reduce="item => item.id"
										v-model="personnel_id"
										placeholder="Personnel" 
									
          > 
          </v-select> 
        </CCol> 
        <CCol lg="3">
         <div class="form-group">
									
									<Datepicker 
										input-class="form-control bg-white" 
										v-model="delivery_date_from"
										placeholder="Delivery Date From"  
									>
									</Datepicker> 
								</div> 
        </CCol>
        <CCol lg="3">
         <div class="form-group">
									
									<Datepicker 
										input-class="form-control bg-white" 
										v-model="delivery_date_to"
										placeholder="Delivery Date To"  
									>
									</Datepicker> 
								</div> 
        </CCol>
      
         <CCol lg="3">
           <v-select 
            label="label" 
            :options="[
             
                  { label: 'For Adjustment',
										value: 'for adjustment'
											
               }, 
               
                  { label: 'Done',
										value: 'done'
                
               }
              
            ]"
            :reduce="item => item.value"
            v-model="status"
            placeholder="Status"
          > </v-select> 
        </CCol>
      </CRow>
      <CRow class="float-right">
        <CCol lg="12" class=" mt-1">
          <CButton size="sm" color="info" variant="outline"  @click="search">Search</CButton>
       
          <CButton size="sm" color="info" variant="outline" @click="clearSearch" style="margin-left: 10px"  >Clear Filter</CButton>
         
        </CCol>
      </CRow>
  </div>
</template>

<script>
  import config from '../../config.js';
  import axios from '../../axios';
  import style from '../../style.css';
  import vSelect from 'vue-select'
  import Datepicker from 'vuejs-datepicker';
  import 'vue-select/dist/vue-select.css';
    export default {
        name: "AdjustmentSearch",
        data() {
          return {
            adjustment_id: "",
            booking_no: "",
            plate_no:"",
           destination:"",
            delivery_date_from:"",
            delivery_date_to:"",
            personnel_id: "",
            status:"",
           
           
          	personnelList: {
				    data: []
			},
          }
        },
      mounted() {        
        this.getPersonnel();
      },
      components: {vSelect, Datepicker},
      methods: {
        search() {
          const data = {
            adjustment_id: this.adjustment_id,
            booking_no: this.booking_no,
            plate_no: this.plate_no,
            destination: this.destination,
            delivery_date_from: this.delivery_date_from,
            delivery_date_to: this.delivery_date_to,
            personnel_id: this.personnel_id,
            status: this.status
          }
          this.$emit("adjustment-search-query", data)
        },
        clearSearch() {
            this.adjustment_id ="";
            this.booking_no ="";
            this.plate_no ="";
            this.destination ="";
            this.delivery_date_from ="";
            this.delivery_date_to ="";
            this.personnel_id ="";
            this.status ="";
          // this.maker_model_id = "";
          // this.year = ""; 
          const data = {
            adjustment_id: this.adjustment_id,
            booking_no: this.booking_no,
            plate_no: this.plate_no,
            destination: this.destination,
            delivery_date_from: this.delivery_date_from,
            delivery_date_to: this.delivery_date_to,
            personnel_id: this.personnel_id,
            status: this.status
            // maker_model_id: this.maker_model_id,
            // year: this.year, 
          }
          this.$emit("adjustment-search-query", data)
        },

       
        getPersonnel(){

	    	axios.get(config.api_path+'/reference/personnel-list')
	    	.then(response => {
	    		this.personnelList = response.data;
	    	
	    	})

	    },


	  
      }
    }
</script>

<style scoped>

</style>
