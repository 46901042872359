<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5>Adjustments ({{dataList.total}})</h5>					
			</CCol>
	     		 <CCol lg="6">
					  <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <CButton 
						v-if="config.getPermission('adjustment').create"
						size="sm" style="margin-right: 10px" 
						color="success"  @click="addModal()" 
						class="float-lg-right"><i class="fa fa-plus"></i>Add Adjustment
					</CButton> 
						   <CButton 
					 v-if="config.getPermission('adjustment').download"
					 size="sm" style="margin-right: 10px" 
					 color="btn btn-outline-primary" @click="download()" 
					 class="float-lg-right">
					 <i class="fa fa-download"></i> Download</CButton>

			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @adjustment-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>			
	       <!-- <CCol lg="12" class="text-center" v-if="isLoading"> 
				  <div class="spinner-border m-5" role="status">
						<span class="sr-only">Loading...</span>
					</div>
			</CCol> -->
		<CCol lg="12" >  
			<div class="table-responsive-sm table-responsive-md">
	      		<CDataTable
				  :loading="isLoading"
			      	:items="dataList.data"
			      	:fields="fields"
					hover
					outlined
					pagination
					:itemsPerPage="20"
					itemsPerPageSelect
					
				
					
					
			    >  
				<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
				</template>
			    	<template #action="{item}">
				        <td style="padding: 5px; width: 100px">
				        	<CDropdown 
				                toggler-text="Select"  
								size="sm"
				                color="info"
				            >
				            	<CDropdownItem v-if="config.getPermission('adjustment').view" @click="viewModal(item)"><i class="fa fa-eye" >View</i></CDropdownItem>  
				            	<CDropdownItem v-if="config.getPermission('adjustment').update && item.status!='done'" @click="updateModal(item)"><i class="fa fa-pencil-square-o" > Update </i></CDropdownItem>   
				            	<CDropdownItem v-if="config.getPermission('adjustment').delete" @click="deleteItem(item)"><i class="fa fa-trash" > Delete</i></CDropdownItem>   
				            </CDropdown>
				        </td>
			      	</template> 
			    </CDataTable> 
			    <!-- <CPagination
		          	:active-page.sync="currentPage"
		          	:pages="Math.ceil(dataList.total / 5)"
		          	:activePage="currentPage"
		          	@update:activePage="updatePage"
		        />  -->
			</div>

			
	      	</CCol>
	    </CRow> 
		</CCard>
<!-- VIEW -->
		<CModal :title="title" :show.sync="formModal" @close="closeModal()" color="info" size="md">
			
           	<form @submit.prevent="submit"> 
		
			
		<!-- <CCard class="p-4 pl-2 pr-2 ml-2 mr-2 mt-2"> -->
				<CRow>
					
					<CCol lg="12">
						<div class="form-group">
							<label style="font-size:13px" class="mr-2">Crew (Personnel Name)  <span v-if="!is_disabled" class="text-danger">* </span></label>
							<v-select 
								label="name"
								:disabled="is_disabled"
								:options="personnelList.data"
								:reduce="item => item.id"
								v-model="adjustmentParam.personnel_id"
								
								placeholder="Select.." 
							>
								<!-- <template #search="{attributes, events}">
									<input
										class="vs__search"
										:required="!adjustmentParam.personnel_id"
										v-bind="attributes"
										v-on="events"
									/>
								</template> -->
							</v-select>
						</div> 
					</CCol>  
				</CRow>
				<CRow>
					<CCol class="col-md-12">
						<div class="form-group">
							<label style="font-size:13px" class="mr-2">Booking No.  <span v-if="!is_disabled" class="text-danger">* </span> <i v-if="!is_disabled" class="fa fa-question-circle" title="Type in booking no. then click Search. Click Select button to use booking No." aria-hidden="true"></i></label>
							<div class="input-group mb-3">
								<input :disabled="is_disabled" type="search" @change="bookingChanged" placeholder="Search booking no." class="form-control" v-model="booking_search" aria-describedby="basic-addon2">
								<div class="input-group-append" v-if="!is_disabled">
									<a href="javascript:void(0)" :class="{disabled: !booking_search}" class="btn btn-info" @click="searchBooking">
										<i class="fa fa-search"></i> Search
									</a>
								</div>
																																									
							</div>
						</div> 
					</CCol>  
				</CRow>
				<CRow>
					<CCol class="col-md-12 pb-3" v-if="showSearchBooking && !is_seaching">
						<div class="list-group" style="max-height:500px;overflow-y:auto" v-if="has_searched && bookings.length">
							<a href="#" class="list-group-item list-group-item-action" v-for="(b, index) of bookings" :key="index">
								<div class="d-flex w-100 justify-content-between">
								<h5 class="mb-1">{{b.booking_no}}</h5>
								<button class="px-3 btn btn-success btn-sm" @click="setBookingId(b)">Select</button>
								</div>
								<small class="mb-1">
									{{b.delivery_date | formatDate}}
								</small>
								<br>
								<small>{{b.zone}}</small>
							</a>
						</div>
						<div class="mb-2" v-if="has_searched && !bookings.length">
							<em class="mb-0 text-danger">No booking found.</em>
						</div>
					</CCol>  
					
				</CRow>
					<CRow>
					<CCol lg="6">
						<label style="font-size:13px" class="mr-2"> Delivery Date</label>
						<CInput
		                  readonly
		                  :value="adjustmentParam.delivery_date | formatDate" 
		                  type="text"
						 
						
		                />
					</CCol>			
					<CCol lg="6">
						<label style="font-size:13px" class="mr-2">Plate No.</label>
						<CInput
		                  readonly
		                  :value="adjustmentParam.plate_no" 
		                  type="text"
						
		                />
					</CCol>
				</CRow>
				
				<CRow>
					<CCol lg="12">
						<label style="font-size:13px" class="mr-2">Zone</label>
						<CInput
		                  readonly
		                  :value="adjustmentParam.zone" 
		                  type="text"
						  
		                />
					</CCol>
				</CRow>
				<CRow>
				 	<CCol lg="12">
						<label style="font-size:13px" class="mr-2">Rate <span  v-if="!is_disabled" class="text-danger">* </span></label>
						<CInput
						:disabled="is_disabled"
		                  v-model="adjustmentParam.rate" 
		                  type="text"
						  v-on:keypress="NumbersOnly"
						 
		                />
					</CCol>
				</CRow>
				
				<CRow>
					<CCol lg="12">
						
						<label style="font-size:13px" class="mr-2">Remarks</label>
						<textarea :disabled="is_disabled" class="form-control" v-model="adjustmentParam.remarks"></textarea>
					</CCol>			
				</CRow>
			
		<!-- </CCard>		 -->
	            <div slot="footer" class="w-100 mt-3">
					<!-- to avoid enabling button via inspect element -->
					<template v-if="!is_disabled">
	            	<CButton size="sm" type="submit" style="border-radius: .2rem; color: white;" v-if="is_valid" color="info" class="ml-1 mr-1 float-right">
	                    Save
	                </CButton>
					<CButton  size="sm" v-else type="button" style="border-radius: .2rem; color: white;" disabled color="info" class="ml-1 mr-1 float-right">
	                    Save
	                </CButton>
					</template>
	                <CButton  size="sm" style="border-radius: .2rem; color: white;" color="danger" class="ml-1 mr-1 float-right" @click="formModal = false">
	                    Cancel
	                </CButton>
	                
	            </div>
          	</form>
           	<div slot="footer" class="w-100"></div>
        </CModal>

		
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'; 
import moment from 'moment';

export default {
	mounted(){  
		this.getData();  
	   this.getPersonnel();
	},
	filters: {
		formatDate(val) {
			if(val) 
			return moment.unix(val).format('MM/DD/YYYY')
			return '';
		},
		uppercase(val) {
			if(val)
				return val.toUpperCase();
			return ''
		}
	},
	watch: {
		booking_search(val) {
			if(!val) {
				this.bookings = [];
				this.has_searched = false;
				this.adjustmentParam.booking_id = null;
				this.adjustmentParam.booking_label = null;
			}
		}
	},
	computed: {
		is_valid() {
			return this.adjustmentParam.booking_id && this.adjustmentParam.personnel_id
		},
		is_disabled() {
			return this.mode.view;
		}
	},
	data(){
		return{
			limit:10,
			isLoading: false,
			config,
			transferParam: {
				asset_no : '',
				current_location_id : '',
				current_location_label : '',
				transfer_date : null,
				transfer_to : {},
				mts_no : '',
				remarks: '',
				id: null,
				old_value : ''

			},
			dataParams: {
				
			},
			
			formModal: false,
			// showViewModal: false,
			dataList: [],  
	     	depotName: "", 
			fields: [
				{
					key: 'adjustment_id', 
					label: 'Adjustment ID'
				},
				{
					key: 'personnel', 
					label: 'Personnel'
				}, 
				{
					key: 'booking_no', 
					label: 'Booking No.'
				},
				{
					key: 'plate_no', 
					label: 'Plate No.'
				},
				{
					key: 'delivery_date_f', 
					label: 'Delivery Date'
				},
				{
					key: 'destination', 
					label: 'Zone',
				}, 
				{
					key: 'rate_f', 
					label: 'Rate',
				}, 
				{
					key: 'status', 
					label: 'Status'
				},
				{
					key: 'action', 
					label: 'Action'
				}
			],
			// currentPage: 1,  


			editMode: false,
			selectedRow: {},
			showCollapse: false,
			
			filter: {
				adjustment_id :"",
				booking_no :"",
				plate_no :"",
				destination :"",
				personnel_id :"",
				delivery_date_from:"",
	            delivery_date_to:"",
				status : ""
	           
			},
			
			
			personnelList: {
				data: []
			},
			
			title: 'Transfer Furnishing',
			showViewModal : false,
			history : [],
			showSearchBooking: false,
			bookings: [],
			booking_search: null,
			has_searched: false,
			adjustmentParam: {
				personnel_id: '',
				booking_id : null,
				booking_label : null,
				delivery_date: null,
				zone : null,
				plate_no : null,
				id: null
			},
			is_seaching: false,
			mode : {
				view: false,
				new: true,
				edit: false
			}
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect},
	methods: {
		download() {
			this.getData('download');
		},
		NumbersOnly(ev) {
			ev = (ev) ? ev : window.event;
			var charCode = (ev.which) ? ev.which : ev.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
				ev.preventDefault();;
			} else {
				return true;
			}
    },
		changePersonnel(ev) {
			console.log('personed')
			this.$set(this.adjustmentParam,'personnel_id', ev)
		
		},
		bookingChanged(ev) {
			let arr = [];
			console.log(ev,'changed')
			let val = ev.target.value;
			if(val) {
				arr = this.bookings.filter(item => item.booking_no == val);
				if(!arr.length) {
					this.adjustmentParam.booking_id =null;
					this.adjustmentParam.booking_label =null;
					this.adjustmentParam.plate_no = null;
					this.adjustmentParam.delivery_date = null;
					this.adjustmentParam.zone = null;
					console.log('aaaaaaaaaa')
				}
				else {
					console.log(arr,'sdasd')
					this.adjustmentParam.booking_id = arr[0].id;
					this.adjustmentParam.booking_label = arr[0].booking_no;
					this.adjustmentParam.plate_no = arr[0].plate_no;
					this.adjustmentParam.delivery_date = arr[0].delivery_date;
					this.adjustmentParam.zone = arr[0].zone;
					// this.adjustmentParam.personnel_id = arr[0].personnel_id;
					
				}
			}
		},
		setBookingId(param) {
			this.$set(this.adjustmentParam,'booking_id', param.id);
			this.$set(this.adjustmentParam,'booking_label', param.booking_no);
			this.$set(this.adjustmentParam,'plate_no', param.plate_no);
			this.$set(this.adjustmentParam,'delivery_date', param.delivery_date);
			this.$set(this.adjustmentParam,'zone', param.zone);
			this.booking_search = param.booking_no;
			this.bookings = [];
			this.has_searched = false;

			console.log(this.adjustmentParam)
		},
		clearBooking() {
			this.booking_search = null;
		},
		
		searchBooking() {
			this.showSearchBooking = true;
			this.getBookings();
			this.has_searched = true;
		},
		getBookings() {
			this.is_seaching = true;
			axios.get(config.api_path+'/reference/bookings-list',{
				params : {
					search: this.booking_search ?? null,
					personnel_id : this.adjustmentParam.personnel_id
				}
			}).then(res => {
				const {data} = res.data;
				this.bookings = data;
				this.is_seaching = false;
			}).catch(err => {
				this.is_seaching = false;
			})
		},
	getBadge (status) {
    	return status === 'for adjustment' ? 'primary'
             : status === 'done' ? 'success' : 'secondary'
    },
	submit() {
		this.$showLoading(true)
			
		axios.post(config.api_path+'/adjustment/save',{
			personnel_id:this.adjustmentParam.personnel_id,
			booking_id:this.adjustmentParam.booking_id,
			rate:this.adjustmentParam.rate,
			remarks:this.adjustmentParam?.remarks ?? null,
			id: this.adjustmentParam?.id ?? null,
			status:"for adjustment"
			
		}).then(res => {
			this.$showLoading(false)
			this.formModal = false;
			this.getData()
					
		})
		.catch(err => {
			this.$showLoading(false)
		})
		if(this.adjustmentParam.id != null){
			Swal.fire({
					title: 'Success',
					text: "Adjustment successfully updated.",
					icon: 'success', 
				})
		}else {
			Swal.fire({
					title: 'Success',
					text: "Adjustment successfully saved.",
					icon: 'success', 
				})
		}
	},
	transferToChanged(item) {
		this.transferParam.transfer_to = item;
	},

	addModal(){
		this.setMode('new')
		this.formModal = !this.formModal;
		this.title = "Add Adjustment";
		this.resetParam()
	},
	fillParam(item) {
		this.adjustmentParam = {
			personnel_id: item.personnel_id,
			booking_id : item.booking_id,
			booking_label : item.booking_no,
			delivery_date: item.delivery_date,
			zone : item.destination,
			plate_no : item.plate_no,
			rate : item.rate,
			remarks : item.remarks,
			id : item?.id ?? null
		}

		this.booking_search = item.booking_no;
	},
	resetParam() {
		this.adjustmentParam = {
			personnel_id: '',
			booking_id : null,
			booking_label : null,
			delivery_date: null,
			zone : null,
			plate_no : null,
			id: null
		}
		this.booking_search = ''
	},
	setMode(type) {
		this.mode = {
			view: false,
			edit : false,
			new : false
		}
		this.mode[type] = true;
	},
	viewModal(item) {
		console.log(item)
		this.setMode('view')
		this.$showLoading(true)
		axios.get(config.api_path+'/adjustment/detail',{
			params: {id : item.id}
		}).then(res => {
			const {data, status} = res.data;
			console.log(data)
			this.fillParam(data)
			this.$showLoading(false);
			
			this.formModal = true;
			this.title = "View Adjustment";
			
		})
		.catch(err => {
			this.$showLoading(false)
			
		})
	},
	closeTransfer() {
		this.formModal = false;
	},


	updateModal(item) {
		this.title = "Update Adjustment";
		this.selectedRow = item;
		this.dataParams = item;
		this.formModal = true;
		this.editMode = true;
		this.setMode('edit')
		this.$showLoading(true)
		axios.get(config.api_path+'/adjustment/detail',{
			params: {id : item.id}
		}).then(res => {
			const {data, status} = res.data;
			console.log(data)
			this.fillParam(data)
			this.$showLoading(false);
			
			this.formModal = true;
			this.title = "Update Adjustment";			
		})
		.catch(err => {
			this.$showLoading(false)
			
		})
	},
	closeModal() {
		console.log('oyet')
	},
		reloadData(){
			this.getData();  
		},
		// updatePage(data){
		// 	this.currentPage = data;
		// 	this.getData();
		// },   

	    search(event){
	    	this.filter = event; 
	    	this.getData();  
	    }, 

	    getData(mode = 'list'){  
			
			
	    	var delivery_date_from = this.filter.delivery_date_from;
	    	if(delivery_date_from != ""){
	    		delivery_date_from = moment(delivery_date_from).format("MM/DD/YYYY"); 
	    	}

	    	var delivery_date_to = this.filter.delivery_date_to;
	    	if(delivery_date_to != ""){
	    		delivery_date_to = moment(delivery_date_to).format("MM/DD/YYYY");
	    		
	    	}

	    	var personnel_id = this.filter.personnel_id;
	    	if( personnel_id == "all" ||  personnel_id == null){
	    		 personnel_id = ""
	    	}
	    	
			if(mode == 'list')
			this.isLoading = true;
			else
			this.isLoading = true;

	    	axios.get(config.api_path+'/adjustment/list',{
				params : {
					adjustment_id :this.filter.adjustment_id,
					plate_no: this.filter.plate_no,
					zone: this.filter.destination,
					booking_no :this.filter.booking_no,
					personnel_id : this.filter.personnel_id,
					delivery_date_from : delivery_date_from,
					delivery_date_to : delivery_date_to,
					status: this.filter.status,
					mode
					// page : this.currentPage,
					// depot_id : this.filter.depot_id,
					// limit : 20,
					// company_id : this.user_details.company_id
				}
			}
			)
	    	.then(response => {
				if(mode == 'list')
				this.isLoading = false;
				else
				this.isLoading = false;
					
				if(mode == 'list') {
					this.dataList = response.data;
					this.dataList.data = this.dataList.data.map(item => {
						item.delivery_date_f = item.delivery_date ? moment.unix(item.delivery_date).format('MM/DD/YYYY') : '';
						item.rate_f = item.rate ? item.rate.toFixed(2) : '';
						return item;
					});
				}
				else {
					window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 

					setTimeout(() => {
						axios.get(`${config.api_path}/remove-file`,{
							params : {
								filename : response.data.file
							}
						})	
					}, 2000);
				}
	    	})
			.catch(err => {
				console.error(err)
				if(mode == 'list')
				this.isLoading = false;
			else
			this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				if(mode == 'list')
				this.isLoading = false;
			else
			this.isLoading = false;
			})
	    },

	    
	  
  	getPersonnel(){

	    	axios.get(config.api_path+'/reference/personnel-list')
	    	.then(response => {
	    		this.personnelList = response.data;
	    	
	    	})

	    },

	
		 deleteItem(item){
	    	Swal.fire({
				icon:'warning',
				title: 'Are you sure you want to delete this adjustment?', 
				showCancelButton: true,
				confirmButtonText: `Yes, delete`, 
				confirmButtonColor : 'red',
        		reverseButtons: true,

			}).then((result) => { 
				if (result.isConfirmed) {
					axios.delete(config.api_path+`/adjustment/${item.id}`)
			    	.then(response => {
			    		this.getData(); 
			    		Swal.fire({
							title: 'Success!',
							text: "Adjustment successfully deleted.",
							icon: 'success', 
						})  
			    	})
				}
			})  
	    }}


}
</script>
